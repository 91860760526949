import React from "react";

const HowToLeadFuture = () => {
  return (
    <div className="howToLead-section">
      <div className="container">
        <p className="info-text">HOW DO YOU WANT TO LEAD IN THE FUTURE?</p>
        <h2 className="sub-header">From Duty to Advantage</h2>
        <p className="desc">
          As the 2025 EU Green Deal unfolds, Digital Product Passport becomes
          essential, enabling efficient information sharing across value chains.
          With sectors like manufacturing, tech, and food leading the way, its
          impact expands to various products, from textile, furniture to
          everyday consumer goods. Embracing DPP isn’t just adaptation - it’s
          leading in transparency and trust.
        </p>
      </div>
    </div>
  );
};

export default HowToLeadFuture;
