import React, { useEffect, useState } from "react";
import homeElement from "../img/home-element-id.jpg";
import HomeData from "./HomeData";
import { CSSTransition, SwitchTransition } from "react-transition-group";

interface HomeSectionProps {}

let interval: NodeJS.Timeout | undefined;

export default function HomeSection(props: HomeSectionProps) {
    const data = HomeData();
    const [selectedEl, setSelectedEl] = useState(0);

    useEffect(() => {
        if (!interval) {
            //This is how you use setInterval to change the active index every 5 seconds
            interval = setInterval(() => {
                setSelectedEl((previous) => {
                    if (previous === data.length - 1) {
                        return 0;
                    } else {
                        return previous + 1;
                    }
                });
            }, 6000); //Change every 10 seconds
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            document.body.style.setProperty(
                "--theme-color",
                data[selectedEl].color
            );
        }, 200);
    }, [selectedEl]);

    function visitLink() {
        //let link = data[selectedEl].link;
        //window.open(link, "_blank");
    }

    function existsInArray(arr: number[], val: number) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === val) {
                return true;
            }
        }
        return false;
    }

    return (
        <div className="home-section">
            {/* home page image  */}

            {/* home text  */}
            <div className="home-text">
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        classNames="fade"
                        addEndListener={(
                            node: HTMLElement,
                            done: () => void
                        ) => {
                            node.addEventListener("transitionend", done, false);
                        }}
                        key={selectedEl}
                    >
                        <h1 className="text">
                            {data[selectedEl].title.map((val, key) => {
                                return (
                                    <span
                                        key={key}
                                        className={
                                            "home-text-el " /* +
                                            (existsInArray(data[selectedEl].highLightEl, key)
                                                ? "home-text-underline-main"
                                                : "home-text-underline") */
                                        }
                                    >
                                        <span
                                            key={key + "_1"}
                                            className="home-text-underline-main"
                                        >
                                            &nbsp;
                                        </span>
                                        {val.split("").map((char, key) => {
                                            return (
                                                <span
                                                    key={key}
                                                    className="home-text-underline-main"
                                                >
                                                    {char}
                                                </span>
                                            );
                                        })}
                                        <span
                                            key={key + "_2"}
                                            className="home-text-underline-main"
                                        >
                                            &nbsp;
                                        </span>
                                    </span>
                                );
                            })}
                        </h1>
                    </CSSTransition>
                </SwitchTransition>

                <div className="home-link m-hide">
                    <button
                        className="btn-mynextid"
                        onClick={() => {
                            window.location.href = "https://dpp.mynext.id";
                        }}
                    >
                        SEE IT IN ACTION
                    </button>
                </div>
            </div>

            <img className="home-bg" src={data[selectedEl].image} alt=""/>
            <div className="home-link m-show">
                <button
                    className="btn-mynextid"
                    onClick={() => {
                        window.location.href = "https://dpp.mynext.id";
                    }}
                >
                    SEE IT IN ACTION
                </button>
            </div>
        </div>
    );
}
