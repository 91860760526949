import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../../img/Verifier/logo-verifier.svg";
import hamburger from "../../../../img/Verifier/nav-hamburger.png";
import Sidebar from "./Sidebar";

interface NavigationProps {
    sidebarOpened: () => void;
}

function Navigation(props: NavigationProps) {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
        props.sidebarOpened();
    };
    const closeSidebar = () => {
        setIsOpen(false);
        props.sidebarOpened();
    };

    return (
        <div className="navbar">
            <img
                src={logo}
                alt="logo"
                className="logo"
                onClick={() => navigate("/")}
            />
            <div className={isOpen ? "no-scroll" : ""}>
                <img
                    src={hamburger}
                    alt="hamburger"
                    className="hamburger"
                    onClick={() => toggleSidebar()}
                />
                <Sidebar isOpen={isOpen} closeSidebar={closeSidebar} />
            </div>
        </div>
    );
}

export default Navigation;
