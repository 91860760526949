import React from "react";
import keyImg0 from "../../../img/AboutUs/trustBuilding-1.png";
import keyImg1 from "../../../img/AboutUs/trustBuilding-2.png";
import keyImg2 from "../../../img/AboutUs/trustBuilding-3.png";
import keyImg3 from "../../../img/AboutUs/trustBuilding-4.png";


export default function TrustBuildingData (){
    return ([
        {
            title: "DECENTRALIZED IDENTITIES",
            image: keyImg0,
            desc: "Creating unique digital identities for both users and your organization simplifies and secures credential sharing throug private digital wallets, while giving customers full control of their information. ",
        },{
            title: "VERIFIABLE CREDENTIALS",
            image: keyImg1,
            desc: "Employing  robust cryptographic measures, we transform personal data and documents into credentials of unqestionable origin, offering accurate and instantly confirmable information.",
        },
        {
            title: "TRUST INFRASTRUCTURE",
            image: keyImg2,
            desc: "Adhering to global standards and protocols, we ensure easy credentials exchange across organizations, while enabling real-time verification of their source, origin and validity.",
        },
        {
            title: "WEB3",
            image: keyImg3,
            desc: "While we base our solutions on web3, we ensure that your systems can effortlessly transition, guaranteeing rapid integration into new or existing products. ",
        }

    ])
}
