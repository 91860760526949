import React from "react";

const VerifierMainText = () => {
    return (
        <div className="verifierMainText-section">
            <div className="container">
                <p className="info-text">
                    {"Simplify Verification, Ensure Trust".toUpperCase()}
                </p>
                <h2 className="sub-header">Scan to Verify Instantly!</h2>
                <p className="desc">
                    Trust digital credentials? You need reliable verification
                    tools. We offer just that—a solution for authorizing
                    personnel and verifying credentials across fields. Scan a QR
                    code to instantly confirm users' credentials, ensuring
                    privacy and convenience. Switch to digital verification to
                    streamline operations and meet security standards. Ready to
                    simplify and build trust? Learn more now!
                </p>
                <button className="btn-mynextid" onClick={() => {}}>
                    MORE
                </button>
            </div>
        </div>
    );
};

export default VerifierMainText;
