import React from "react";
import homeBG from "../../../img/AboutUs/about-us-home.png";

function PrivacyPolicyText() {
    return (
        <>
            <div className="about-us-section">
                <div className="home-text">
                    <h1 className="header">
                        <span className="">
                            <span className="home-text-underline">&nbsp;</span>

                            <span className="home-text-underline">
                                Privacy Policy
                            </span>
                            <span className="home-text-underline">&nbsp;</span>
                        </span>
                    </h1>
                </div>
                <div className="home-elements">
                    <img className="home-bg" src={homeBG} alt="home-bg-image" />
                </div>
            </div>
            <div className="privacy-policy-text">
                <h2>Comming soon...</h2>
            </div>
        </>
    );
}

export default PrivacyPolicyText;
