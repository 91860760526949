import React, { useState } from 'react'
import imagePlus from "../../../img/MyNextId/showcase-plus.png";
import imageMinus from "../../../img/MyNextId/showcase-minus.png";
import ShowcaseBoxLink from './ShowcaseBoxLink';

const ShowcaseBox = (props:any) => {
    const val = props.data;

    const [isOpen, setIsOpen] = useState(false);

    return (

        <div className="" style={{minWidth:"100%" /* width:100%*/}}>
           
            <div  className='showcase-element' style={{display:"flex", flexDirection:"row", flexWrap:"wrap", /*width se zbriše tuki*/ width:"initial"}}>
                <div className={isOpen ? "top2 " : "top"} style={{backgroundColor:`${isOpen ? `#343c6d` : val.color}` ,width:"100%"}}>
                    {isOpen ? <>
                        <div className='showcase-top2'>
                            <img className="showcase-image" src={isOpen ? imageMinus : imagePlus} alt = "" onClick={()=> {setIsOpen(!isOpen)}}></img>
                            <h2 className='showcase-el-mini' style={{color:val.color}}>{val.title}</h2>
                        </div>
                        
                        <p>{val.desc}</p>
                        {val.examples.map((val2:any, key2:any) => {
                            return (
                                <p key={"example-"+key2} className="showcase-examples" >{val2}</p>
                            )   
                        })}
                    </>:<>
                        <img className="showcase-image" src={isOpen ? imageMinus : imagePlus} alt="showcase-image" onClick={()=> {setIsOpen(!isOpen)}}></img>
                        <h2 className='showcase-el-title'>{val.title}</h2>
                    </>}
                    
                </div>

                <div  style={{width:"100%"}}>
                    {val.details.map((val2:any, key2:any) => {
                        return (
                            <ShowcaseBoxLink key={"el-"+key2} index={key2} color={val.color} {...val2}/>
                        )   
                    })}
                </div>
                
            </div>
        </div>
        
    )
}

export default ShowcaseBox