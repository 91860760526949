import React from "react";
import KeyBenefitsData from "./KeyBenefitsData";
interface SectionKeyBenefitsProps {}

export default function KeyBenefitsSection(props: SectionKeyBenefitsProps) {
    const data = KeyBenefitsData();

    return (
        <div className="benefits-section">
            <div className="container">
                <h2 className="sub-header">Key Benefits</h2>
            </div>

            <div className="benefits-container">
                {data.map((item, index) => {
                    return (
                        <div className="benefit" key={index}>
                            <div className="benefit-image">
                                <img src={item.image} alt={`keyImg${index}`} />
                            </div>
                            <div className="benefit-text">
                                <h3>{item.title}</h3>
                                <p>{item.desc}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
