import React from "react";
import elLeft from "../img/rectangle-green.png";
import digitalDocImg from "../../../img/Verifier/digital-doc-img.jpg";

const EmpowerTrust = () => {
    return (
        <div className="empowerTrust-section">
            <div className="container">
                <div className="shadow-container"></div>
                <img src={digitalDocImg} alt="empowerTrust-image" />
            </div>

            <div className="content-digital">
                <p className="info-text">
                    {"Why Choose MyNextID_Verifier?".toUpperCase()}
                </p>
                <h2 className="sub-header">Empower Trust in a Digital Age</h2>
                <p className="desc">
                    In today's world, identity theft and document scams are
                    growing concerns. MyNextID_Verifier leads the way in secure
                    digitization by enabling digital identity verification,
                    fostering trust across organizations while adhering to
                    global standards and regulations, saving time and hassle.
                    Join us to enhance security and simplify verification
                    processes.
                </p>
                {/* <button className="btn-mynextid" onClick={() => {}}>
          MORE
        </button> */}
            </div>
        </div>
    );
};

export default EmpowerTrust;
