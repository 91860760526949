import React from 'react'
import homeElement from "../../../img/AboutUs/home-element-id.png";
import homeBG from "../../../img/AboutUs/about-us-home.png";

const AboutHome = () => {
    return (
        <div className="about-us-section">
            <div className="home-text">
                <h1 className="header">
                
                    <span className="">
                        <span className="home-text-underline">&nbsp;</span>
                        
                            <span className="home-text-underline">
                            About Us
                            </span>
                        <span className="home-text-underline">&nbsp;</span>
                    </span>
                </h1>

                
            </div>
            <div className='home-elements'>
                <img  className="home-bg" src={homeBG} alt="home-bg-image" />
                <img className="home-element-image" src={homeElement} alt="home-element-image" />
            </div>
        </div>
    )
}

export default AboutHome
