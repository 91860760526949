import React from "react";
import digitalDocImg from "../../../img/Dpp/tech-img.jpg";

const WhatIsDPP = () => {
    return (
        <div className="whatIsDpp-section">
            <div className="container">
                <div className="shadow-container"></div>
                <img src={digitalDocImg} alt="" />
            </div>

            <div className="content-digital">
                <p className="info-text">WHAT IS DIGITAL PRODUCT PASSPORT?</p>
                <h2 className="sub-header">
                    Product Details At Your Customers’ Fingertips
                </h2>
                <p className="desc">
                    The Digital Product Passport (DPP) goes beyond being a tool
                    – it’s your blueprint to transparent production. By
                    assigning unique IDs to each product, it provides instant,
                    secure access to vital details like lifecycle, materials,
                    sustainability, and more. With verified information,
                    customers choose trustworthy companies, while your business
                    paves the way to a transparent, customer-focused future.
                    Start your journey today!
                </p>
                {/* <button className="btn-mynextid" onClick={() => {}}>
          MORE
        </button> */}
            </div>
        </div>
    );
};

export default WhatIsDPP;
