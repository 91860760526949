import React, { useEffect, useState } from "react";
import NewsData from "./NewsData";
import { useParams } from "react-router-dom";

const OtherNews = () => {
    const { id } = useParams();
    const [data, setData] = useState<any>({});

    useEffect(() => {
        const tempdata = NewsData().filter((element) => element.id !== id);
        tempdata.splice(3);
        console.log(tempdata);

        if (tempdata !== undefined) {
            setData(tempdata);
            console.log(tempdata);
        }
    }, [id]);

    return (
        <div className="other-news-section">
            {Object.keys(data).length !== 0 ? (
                <div className="home-text">
                    <h1 className="sub-header">
                        <span className="home-text-el">
                            <span className="home-text-underline">&nbsp;</span>

                            <span className="home-text-underline">
                                Other News
                            </span>
                            <span className="home-text-underline">&nbsp;</span>
                        </span>
                    </h1>

                    {data.map((item: any, index: any) => {
                        return (
                            <div key={index + "_other_news"}>
                                <div className="content-news">
                                    <p className="smaller-info-text">
                                        {item.date}
                                    </p>
                                    <h2 className="smaller-sub-header">
                                        {item.title}
                                    </h2>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default OtherNews;
