import React from "react";
import elLeft from "../img/rectangle-green.png";
import digitalDocImg from "../../../img/Dpp/digital-doc-img.jpg";

const DigitalDocSection = () => {
    return (
        <div className="digital-doc-section">
            <div className="container">
                <div className="shadow-container"></div>
                <img src={digitalDocImg} alt="digital-doc-img-image" />
            </div>

            <div className="content-digital">
                <p className="info-text">WHY CHOOSE MYNEXTID_DPP?</p>
                <h2 className="sub-header">
                    Cutting-Edge, Minus The Complexity
                </h2>
                <p className="desc">
                    The MyNextID Digital Product Passport uses EU-endorsed
                    technology to ensure security and simplicity. Each product
                    receives a unique, machine readable ID number, akin to
                    passports, streamlining information sharing. Partnering with
                    us provides a seamless integration with your existing
                    infrastructure, with many potential uses cases to improve
                    product, brand and service experience.
                </p>
                {/* <button className="btn-mynextid" onClick={() => {}}>
          MORE
        </button> */}
            </div>
        </div>
    );
};

export default DigitalDocSection;
