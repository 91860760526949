import React from "react";
import elLeft from "../img/rectangle-green.png";
import digitalDocImg from "../../../img/MyNextId/digital-doc-img.jpg"

const DigitalDocSection = () => {

    return (
        <div className="digitalDoc-section">
            <div className="container">
                <div className="shadow-container"></div>
                <img src={digitalDocImg} alt="digitalDoc-image" />
            </div>
       
            <div className="content-digital">
                <p className="info-text">
                    {"DIGITAL DOCUMENTS AT EVERYONE'S FINGERPRINTS".toUpperCase()}
                </p>
                <h2 className="sub-header">
                    {" "}
                    Verifiable Credentials In Minutes, Across Industries
                </h2>
                <p className="desc">
                    Discover secure, seamless interactions protecting your users' data in
                    today's digital era. Effortlessly issue or verify credentials, from
                    basic proofs to high-assurance ones, with user-friendly onboarding and
                    customization. Explore the possibilities with us!
                </p>
                <button className="btn-mynextid" onClick={() => {
                    const section = document.querySelector('#getStartedCredentials');
                    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }}>
                    MORE
                </button>
            </div>
        </div>
    );
};

export default DigitalDocSection;
