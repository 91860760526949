import React from "react";
import keyImg0 from "../../../img/News/news-1.png";

// Return data needs to be ordered by new entry first
export default function NewsData() {
    return [
        {
            id: "u1n6haSGQRFkE0YU",
            date: "November 9, 2023",
            title: "MyNextID Participated in the Mobile Driver’s License Interoperability Test",
            image: keyImg0,
            desc: "MyNextID joined a three-week test event hosted by SpruceID in collaboration with AAMVA and members of ISO/IEC groups, focusing on the interoperability of mobile driver’s licenses (mDL). We have tested our MyNextID Verifier reader application and MyNextID mDL implementation application, in an effort to drive forward cross-industry interoperability and adoption, while accelerating feedback and clarity for implementations of the standard.",
            details: [
                {
                    title: "",
                    desc: "Objective: The main goal of this test was to collaborate on the ISO/IEC 18013-7 standard. It was designed to promote industry-wide compatibility, accelerate mDL adoption, and offer clear guidance for the standard’s broader implementation.",
                },
                {
                    title: "",
                    desc: "Interoperability Participants: BDR, Credence ID, Google, HID, IDEMIA, ImproveID, MyNextID, NEC, NIST, Okta, OneProof, Panasonic, Samsung, Scytales, SpruceID, Thales.",
                },
                {
                    title: "",
                    desc: "Event Period: August – September 2023",
                },
                {
                    title: "Background on Mobile Driver's Licenses",
                    desc: "With the global shift towards digital solutions, the need for a secure and verifiable digital identity is intensifying. A driver’s license serves beyond just driving – for many, it’s a primary form of identification. The digital transition mandates that these IDs are easily verifiable and provide users greater control over their data and privacy.",
                },
                {
                    title: "The Importance of Interoperability and Standardization",
                    desc: "To operate seamlessly across different sectors and countries, standardized data formats and protocols are essential for mobile driving licences (mDLs). Such standards ensure that verifiers can reliably authenticate an individual’s identity. The ISO/IEC 18013-7 draft technical specification is central, focusing on use cases where parties are remotely connected during presentation.",
                },
                {
                    title: "Our Contribution and Success",
                    desc: "At MyNextID, our products stand as easy-to-use solutions for issuers, verifiers, and digital wallet holders. All our innovations adhere to open standards and follow the guidelines set by the European Blockchain Services Infrastructure (EBSI). See MyNextID applications that are conformant with EBSI Framework. Our positive milestones during the interoperability test showcase our commitment to advancing the mDL ecosystem and whole information exchange with W3C verifiable credentials.  More broadly, we aim to simplify the issuance, holding and verification of verifiable credentials for people and companies. We’ve participated with MyNextID Verifier reader application and MyNextID mDL implementation application and cross-tested other’s applications and implementations that follow OpenID for Verifiable Presentations (OID4VP).",
                },
                {
                    title: "What This Means for MyNextID",
                    desc: "Being a part of this test, alongside notable nameslike Google, Samsung, Okta and Panasonic, is a significant milestone for MyNextID. It highlights our tech maturity and cements our position as innovators in digital identity and verifiable credentials. This interoperability test reaffirms our belief in our products and chosen trajectory.",
                },
                {
                    title: "Looking Ahead",
                    desc: "The future of digital identity systems is bright. Enhanced by trustworthy digital IDs, it paves the way for solutions where all parties can affirm their identities, making online exchanges of sensitive data and credentials more streamlined. With tech giants and industry leaders coming together for such tests, we are shaping an environment where digital credentials are secure and universally accepted. At MyNextID, we’re excited to be at the forefront of this change, pushing boundaries and defining new benchmarks.",
                },
                {
                    title: "FYI",
                    desc: "Official event results publication will be published next week. Stay tuned!",
                },
            ],
        },
    ];
}
