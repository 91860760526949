import React, { useEffect, useState } from "react";
import Navigation from "./Navigation/Navigation";
import HomeSection from "./HomeSection";
import DigitalDocSection from "./DigitalDocSection";
import ShowcaseSection from "./Showcase";
import KeyBenefitsSection from "./KeyBenefitsSection";
import TechSection from "./TechSection";
import TrustBuildingSolutions from "./TrustBuildingSolutions";
import Footer from "./Footer/Footer";
import Credentialing4USection from "./Credentialing4USection";
import BuildingSection from "./BuildingSection";
import SolutionsSection from "./SolutionsSection";
import ContactForm from "./ContactForm";

const MyNextIdApp = () => {

    useEffect(() => {
        require("../../../scss/myNextId.scss");
    }, [])

    const [sidebarOpened, setSidebarOpened] = useState(false);

    return (
        <div className={sidebarOpened ? "main-theme overlay" : "main-theme"}>
            <Navigation
                sidebarOpened={() => setSidebarOpened(!sidebarOpened)}
            />
            <HomeSection />
            <DigitalDocSection />
            <ShowcaseSection />
            <KeyBenefitsSection />
            <Credentialing4USection />
            {/*<BuildingSection /> Waiting for documentation to be ready

             <SolutionsSection /> TODO Missing data */}
            <TechSection />
            <TrustBuildingSolutions />

            {/*<ContactForm  />TODO Define how will we support contact*/}
            <Footer />
        </div>
    );
};

export default MyNextIdApp;
