import React from 'react'
import separator from "../../../img/News/separator.png";

const Separator = () => {
    return (
        <div className="news-seperator-section">
            <div className="container">
                <img src={separator} alt="separator-image" />
            </div>

        </div>
    )
}

export default Separator
