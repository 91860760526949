import React from "react";
import image1 from "../../../img/Verifier/verifier-icon-1.svg";
import image2 from "../../../img/Verifier/verifier-icon-2.svg";
import image3 from "../../../img/Verifier/verifier-icon-3.svg";

function WhyVerifierMatters() {
    return (
        <div className="why-verifier-matters-section">
            <div className="container">
                <p className="info-text">
                    {"Why MyNextID_Verifier Matters?".toUpperCase()}
                </p>
                <h2 className="sub-header">
                    Instant Data Verification, Enhanced Security
                </h2>
                <p className="desc">
                    MyNextID_Verifier boosts security and quickly verifies data.
                    It's a one-stop solution for checking digital credentials
                    without contacting the issuer. Accredited verifiers can use
                    our app to authenticate users and verify credentials
                    instantly. This shift to digital verification cuts down on
                    paperwork, delays, and wait times, while enhancing security
                    and user satisfaction.
                </p>
            </div>

            <div className="sections-container">
                <div className="image-container">
                    <img src={image1} className="verifierIcons" alt="image-1" />
                    <h2 className="image-header">Scan and Verify</h2>
                    <p className="image-desc">
                        Authenticate documents globally with QR scans,
                        simplifying for all.
                    </p>
                </div>
                <div className="image-container">
                    <img src={image2} className="verifierIcons" alt="image-2" />
                    <h2 className="image-header">Meet Customer Demand</h2>
                    <p className="image-desc">
                        Securely check credentials, streamline for customers,
                        reducing paperwork.
                    </p>
                </div>
                <div className="image-container">
                    <img src={image3} className="verifierIcons" alt="image-3" />
                    <h2 className="image-header">Optimize Operations</h2>
                    <p className="image-desc">
                        Streamline the process to quickly verify and instantly
                        detect fraud, ensuring safety for all.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default WhyVerifierMatters;
