import React from "react";
import image1 from "../../../img/Dpp/dpp-matters-build-trust.png";
import image2 from "../../../img/Dpp/dpp-matters-blockchain.png";
import image3 from "../../../img/Dpp/dpp-matters-compliance.png";

function WhyDPPMatters() {
    return (
        <div className="why-dpp-matters-section">
            <div className="container">
                <p className="info-text">
                    WHY DIGITAL PRODUCT PASSPORT MATTERS?
                </p>
                <h2 className="sub-header">
                    Trustworthy Insights for Everyone
                </h2>
                <p className="desc">
                    The Digital Product Passport is your tool for earning
                    consumer trust and enhancing brand transparency. With clear
                    details specific to each product, easily accessible through
                    a simple QR scan, it influences consumer decisions,
                    strengthens your brand’s reputation and sets industry
                    standards. This is your chance to digitize product
                    information and gain a competitive edge in the market.
                </p>
            </div>
            <div className="sections-container">
                <div className="image-container">
                    <img src={image1} alt="" />
                    <h2 className="image-header">Build Trust</h2>
                    <p className="image-desc">
                        Mark each product with a unique, unforgettable QR code.
                    </p>
                </div>
                <div className="image-container">
                    <img src={image2} alt="" />
                    <h2 className="image-header">New Business Models</h2>
                    <p className="image-desc">
                        Transparency enables premium pricing and responsibility.
                    </p>
                </div>
                <div className="image-container">
                    <img src={image3} alt="" />
                    <h2 className="image-header">Compliance</h2>
                    <p className="image-desc">
                        Stay EU Green Deal compliant for market advantage.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default WhyDPPMatters;
