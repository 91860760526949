import React, { useRef, useEffect } from "react";
import closeImg from "../../../../img/MyNextId/sidebar-x.png";
import graphic from "../../../../img/MyNextId/sidebar-graphic.png";

interface SidebarProps {
    isOpen: boolean;
    closeSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, closeSidebar }) => {
    const sidebarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            sidebarRef.current &&
            !sidebarRef.current.contains(event.target as Node)
        ) {
            closeSidebar();
        }
    };
    return (
        <>
            <div className={isOpen ? "sidebar open" : "sidebar"} ref={sidebarRef}>
                <img
                    src={closeImg}
                    className="close-btn"
                    onClick={closeSidebar}
                    alt="X"
                ></img>
                <div className="links">
                    <a href="/home">Home</a>
                    <a href="/news">News</a>
                    <a href="/about-us">About</a>
                    {/*<a href="https://test-docs.mynext.id/api/v2/verifier" target="_blank">Documentation</a>*/}
                </div>
                <div className="graphic">
                    {/* Placeholder for any graphic like an image */}
                    <img src={graphic} alt="Graphic" />
                </div>
            </div>
            <div className={isOpen ? "sidebar-overlay" : ""}></div>
        </>
    );
};

export default Sidebar;
