import React from "react";
import keyImg0 from "../../../img/MyNextId/keyBenefits-1.png";
import keyImg1 from "../../../img/MyNextId/keyBenefits-2.png";
import keyImg2 from "../../../img/MyNextId/keyBenefits-3.png";
import keyImg3 from "../../../img/MyNextId/keyBenefits-4.png";

export default function KeyBenefitsData (){
    return ([
        {
            title: "EASY TO VERIFY, IMPOSSIBLE TO FAKE",
            image: keyImg0,
            desc: "Ensure seamless document integrity with verifiable credentials tech—foolproof verification for absolute trust.",
        },{
            title: "SO MANY USE CASES",
            image: keyImg1,
            desc: "Unlock endless possibilities with universally accepted digital credentials. Sign and match them effortlessly to their physical counterparts.",
        },
        {
            title: "SCAN WITH A QR CODE",
            image: keyImg2,
            desc: "Embrace simplicity with authorized QR code scans, gaining instant, secure access to document information. Bid farewell to in-person contacts.",
        },
        {
            title: "TRUE DIGITIZATION",
            image: keyImg3,
            desc: "Elevate your digital experience beyond PDFs. Genuine digitization awaits—authenticity and convenience combined, without compromise.",
        }

    ])
}
