import React, { useEffect, useState } from "react";
import Navigation from "./Navigation/Navigation";
import HomeSection from "./HomeSection";
import VerifierMainText from "./VerifierMainText";
import WhatIsVerifier from "./WhatIsVerifier";
import WhyVerifierMatters from "./WhyVerifierMatters";
import HowVerifierWorks from "./HowVerifierWorks";
import GetStartedSlider from "./GetStartedSlider";
import HowToLeadFuture from "./HowToLeadFuture";
import Footer from "./Footer/Footer";

import "./scss/verifier.scss";
import EmpowerTrust from "./EmpowerTrust";

const VerifierApp = () => {
    useEffect(() => {
        require("./scss/verifier.scss");
    }, [])
    const [sidebarOpened, setSidebarOpened] = useState(false);
    return (
        <div className={sidebarOpened ? "main-theme overlay verifier-theme" : "main-theme verifier-theme"}>
            <Navigation sidebarOpened={() => setSidebarOpened(!sidebarOpened)} />
            <HomeSection />
            <VerifierMainText />
            <WhatIsVerifier />
            <WhyVerifierMatters />
            <HowVerifierWorks />
            <GetStartedSlider />
            {/* <CoreFeatures /> */}
            <EmpowerTrust />
            <HowToLeadFuture />
            {/* <Pricing /> */}
            {/*<ContactForm />*/}
            <Footer />
        </div>
    );
};

export default VerifierApp;
