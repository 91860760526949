import React from "react";
import elLeft from "../img/rectangle-green.png";
import coreIssue from "../../../img/Dpp/core-issue.png";
import checkMark from "../../../img/Dpp/checkmark-green.png";

function CoreFeatures() {
    return (
        <div className="core-features-section">
            <h2 className="sub-header">Core Features</h2>
            <div className="containers-container">
                <div className="container">
                    <div className="core-features-panel">
                        <div className="panel-container">
                            <img src={coreIssue} alt="core-issue-image" />
                            <div className="core-features-text">
                                <h2 className="small-sub-header">
                                    <span style={{ color: "#03F0AA",display: "contents" }}>
                                        ISSUE&nbsp;
                                    </span>
                                    Digital Product Passport
                                </h2>
                                <p className="desc">
                                    Empower your brand and consumers with
                                    detailed product information.
                                </p>
                                <div className="core-features-list">
                                    <div className="core-features-item">
                                        <img src={checkMark} alt="checkmark-green-image" />
                                        <p className="description">
                                            <b>
                                                Origin & Material
                                                Composition:&nbsp;
                                            </b>
                                            Clearly display where materials come
                                            from.
                                        </p>
                                    </div>
                                    <div className="core-features-item">
                                        <img src={checkMark} alt="checkmark-green-image" />
                                        <p className="description">
                                            <b>
                                                Product Lifecycle &
                                                Traceability:&nbsp;
                                            </b>
                                            Track the product journey from
                                            creation to recycling.
                                        </p>
                                    </div>
                                    <div className="core-features-item">
                                        <img src={checkMark} alt="checkmark-green-image" />
                                        <p className="description">
                                            <b>
                                                Align with EU Directive:&nbsp;
                                            </b>
                                            Support sustainable practices and
                                            policies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shadow-container"></div>
                </div>

                
                <div className="container">
                    <div className="core-features-panel2">
                        <div className="panel-container">
                            <img src={coreIssue} alt="core-issue-image" />
                            <div className="core-features-text">
                                <h2 className="small-sub-header">
                                    <span style={{ color: "#03F0AA",display: "contents"}}>
                                        VERIFY&nbsp;
                                    </span>
                                    Digital Product Passport
                                </h2>
                                <p className="desc">
                                    Deepen trust and ensure authenticity.
                                </p>
                                <div className="core-features-list">
                                    <div className="core-features-item">
                                        <img src={checkMark} alt="checkmark-green-image" />
                                        <p className="description">
                                            <b>Trace the Journey:&nbsp;</b>
                                            Verify transparency and integrity
                                            throughout the product’s lifecycle.
                                        </p>
                                    </div>
                                    <div className="core-features-item">
                                        <img src={checkMark} alt="checkmark-green-image" />
                                        <p className="description">
                                            <b>
                                                Align with EU Directive:&nbsp;
                                            </b>
                                            Adhere to EU sustainability
                                            guidelines.
                                        </p>
                                    </div>
                                    <div className="core-features-item">
                                        <img src={checkMark} alt="checkmark-green-image" />
                                        <p className="description">
                                            <b>Champion Transparency:&nbsp;</b>
                                            Build trust and avoid gray markets
                                            across the value chain.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shadow-container2"></div>
                </div>
            </div>
        </div>
    );
}

export default CoreFeatures;
