import React from "react";

const HowToLeadFuture = () => {
    return (
        <div className="howToLead-section ">
            <div className="container">
                <p className="info-text">
                    HOW DO YOU WANT TO LEAD IN THE FUTURE?
                </p>
                <h2 className="sub-header">
                    Start verifying credentials digitally!
                </h2>
                <p className="desc">
                    Enhance efficiency, security and control for your
                    organization, while also giving your personnel an ultimate
                    tool—a mobile app for trustworthy, instant inspections. Join
                    us to simplify verification for everyone and unlock the
                    future of credential management!
                </p>
            </div>
        </div>
    );
};

export default HowToLeadFuture;
