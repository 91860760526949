import React, { useState } from "react";
import sliderLeft from "../../../img/Verifier/slider-left.png";
import sliderRight from "../../../img/Verifier/slider-right.png";

function GetStartedSlider() {
    const [slideIndex, setSlideIndex] = useState(0);

    const toggleSlideLeft = () => {
        if (slideIndex !== 0) {
            setSlideIndex(slideIndex - 1);
        }
    };

    const toggleSlideRight = () => {
        if (slideIndex !== 3) {
            setSlideIndex(slideIndex + 1);
            console.log(slideIndex + 1);
        }
    };
    return (
        <div id="getStartedSteps" className="getStarted-section">
            <div className="mobile-hide">
                <div className="slider-arrows left">
                    <img
                        src={sliderLeft}
                        alt="slider-left"
                        className="arrow"
                        onClick={toggleSlideLeft}
                    />
                </div>

                <div style={{ width: "720px", overflow: "hidden" }}>
                    <div
                        className="steps "
                        style={{
                            transform: `translateX(${slideIndex * -720}px)`,
                        }}
                    >
                        {/*showcases*/}
                        <div className="grid-container">
                            <div className="item item1">1</div>
                            <div className="item item2">REGISTER</div>
                            <div className="item item3">
                                Sign up to establish your organization as a
                                verifier.
                            </div>
                        </div>
                        <div className="grid-container">
                            <div className="item item1">2</div>
                            <div className="item item2">ONBOARD PERSONNEL</div>
                            <div className="item item3">
                                Add authorized employees for verifying
                                credentials.
                            </div>
                        </div>
                        <div className="grid-container">
                            <div className="item item1">3</div>
                            <div className="item item2">
                                CONFIGURE VERIFICATION
                            </div>
                            <div className="item item3">
                                Select credential types for verification and
                                trust frameworks.
                            </div>
                        </div>
                        <div className="grid-container">
                            <div className="item item1">4</div>
                            <div className="item item2">QR SCAN</div>
                            <div className="item item3">
                                Utilize the app to verify credentials with a
                                simple QR code scan.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-arrows right">
                    <img
                        src={sliderRight}
                        alt="slider-right"
                        className="arrow"
                        onClick={toggleSlideRight}
                    />
                </div>
            </div>

            {/* --------------------------------------------------------------------------------------------- */}
            <div className=" mobile-show ">
                <div className="slider-arrows left">
                    <img
                        src={sliderLeft}
                        alt="slider-left"
                        className="arrow"
                        onClick={toggleSlideLeft}
                    />
                </div>

                <div
                    className="steps-container"
                    style={{ width: "calc( 100% - 140px)" }}
                >
                    <div
                        className="steps "
                        style={{
                            transform: `translateX(${slideIndex * -290}px)`,
                        }}
                    >
                        <div className="grid-container">
                            <div className="item item1">1</div>
                            <div className="item item2"></div>
                            <div className="item item3">
                                <h2>REGISTER</h2>
                                Sign up to establish your organization as a
                                verifier.
                            </div>
                        </div>
                        <div className="grid-container">
                            <div className="item item1">2</div>
                            <div className="item item2"></div>
                            <div className="item item3">
                                <h2>ONBOARD PERSONNEL</h2>
                                Add authorized employees for verifying
                                credentials.
                            </div>
                        </div>
                        <div className="grid-container">
                            <div className="item item1">3</div>
                            <div className="item item2"></div>
                            <div className="item item3">
                                <h2>CONFIGURE VERIFICATION</h2>
                                Select credential types for verification and
                                trust frameworks.
                            </div>
                        </div>
                        <div className="grid-container">
                            <div className="item item1">4</div>
                            <div className="item item2"></div>
                            <div className="item item3">
                                <h2>QR SCAN</h2>
                                Utilize the app to verify credentials with a
                                simple QR code scan.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-arrows right">
                    <img
                        src={sliderRight}
                        alt="slider-right"
                        className="arrow"
                        onClick={toggleSlideRight}
                    />
                </div>
            </div>
        </div>
    );
}

export default GetStartedSlider;
