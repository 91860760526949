import React, { useState } from "react";
import sliderLeft from "../../../img/MyNextId/slider-left.png";
import sliderRight from "../../../img/MyNextId/slider-right.png";
import ShowcaseData from "./ShowcaseData";
import ShowcaseBox from "./ShowcaseBox";
import ShowcaseBoxMobile from "./ShowcaseBoxMobile";

function ShowcaseSection() {
    const [slideIndex, setSlideIndex] = useState(0);

    const toggleSlideLeft = () => {
        setSlideIndex(
            (prevIndex) =>
                (prevIndex - 1 + showcasesMobile.length) %
                showcasesMobile.length
        );
    };

    const toggleSlideRight = () => {
        setSlideIndex(
            (prevIndex) =>
                (prevIndex + 1 + showcasesMobile.length) %
                showcasesMobile.length
        );
        //setShowcasesMobile(showcasesMobile.slice(slideIndex, showcasesMobile.length).concat(showcasesMobile.slice(0, slideIndex)))
    };

    const showcases = ShowcaseData().map((val, key) => {
        return <ShowcaseBox data={val} key={key} />;
    });

    const showcasesMobile = ShowcaseData().map((val, key) => {
        return <ShowcaseBoxMobile data={val} key={key} />;
    });

    return (
        <div id="getStartedCredentials" className="getStarted-section">
            <div className="mobile-hide" >
                <div className="slider-arrows left" >
                    <img
                        src={sliderLeft}
                        alt="slider-left"
                        className="arrow"
                        onClick={toggleSlideLeft}
                    />
                </div>

                <div
                    style={{ width: "calc(100% - 160px)", overflow: "hidden" }}
                >
                    <div
                        className="steps "
                        style={{
                            transform: `translateX(${slideIndex * -550}px)`,
                        }}
                    >
                        {showcases}
                    </div>
                </div>

                <div className="slider-arrows right">
                    <img
                        src={sliderRight}
                        alt="slider-right"
                        className="arrow"
                        onClick={toggleSlideRight}
                    />
                </div>
            </div>

            {/* --------------------------------------------------------------------------------------------- */}

            <div className="mobile-show center">
                <div className="slider-arrows left">
                    <img
                        src={sliderLeft}
                        alt="slider-left"
                        className="arrow"
                        onClick={toggleSlideLeft}
                    />
                </div>

                <div className="showcase-main-box">
                    <div className={"steps"}>{showcasesMobile[slideIndex]}</div>
                </div>

                <div className="slider-arrows right">
                    <img
                        src={sliderRight}
                        alt="slider-right"
                        className="arrow"
                        onClick={toggleSlideRight}
                    />
                </div>
            </div>
        </div>
    );
}

export default ShowcaseSection;
