import React from "react";

import buildingImg from "../../../img/Verifier/building-el-small.jpg";

const HowVerifierWorks = () => {
    return (
        <div className="how-verifier-works-section">
   
            <div className="container-image">
                <div className="shadow-container"></div>
                <img src={buildingImg} className="shadow-img" alt="building-image"/>
            </div>

            <div className="container">
                <p className="info-text">
                    {"How MyNextID_Verifier Works?".toUpperCase()}
                </p>
                <h2 className="sub-header">Instant Checks, Global Trust</h2>
                <p className="desc">
                    MyNextID seamlessly interacts with users' digital wallets
                    and issuing organizations, enabling instant checks of user
                    identity and the validity of their credentials. Your team
                    can utilize a dedicated app for swift verification with a
                    single QR scan, ensuring efficiency and trustworthiness.
                    It's as simple as that.
                </p>
            </div>
        </div>
    );
};

export default HowVerifierWorks;
