import React from "react";
import TrustBuildingData from "./TrustBuildingData";

const TrustBuildingSolution = () => {
    const data = TrustBuildingData();

    return (
        <div className="building-solutions-section">
            <div className="container">
                <p className="info-text">TRUST-BUILDING SOLUTIONS</p>
                <h2 className="sub-header">
                    Turning Digital Trust into Reality
                </h2>
                <p className="desc">
                    Trust forms the cornerstone of every interaction, and
                    MyNextID’s solutions make achieving digital trust easy. We
                    help organizations authenticate identities, manage diverse
                    credentials, and enable instant validation of critical
                    attributes, while users securely manage their documents in
                    one place, sharing only what’s required. How?
                </p>
            </div>

            <div className="benefits-container">
                {data.map((item: any, index: any) => {
                    return (
                        <div className="benefit" key={index}>
                            <div className="benefit-image">
                                <img src={item.image} alt={`keyImg-${index}`} />
                            </div>
                            <div className="benefit-text">
                                <h3>{item.title}</h3>
                                <p>{item.desc}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TrustBuildingSolution;
