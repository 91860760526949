export default function ShowcaseData (){
    return ([
        {
            title: "WORK",
            color: "#6255ac",
            desc: "Manage work-related credentials, securely stored in digital wallets, with the option to showcase achievements.",
            examples:['Verifiable CV',
                'Proffesional Certification',
                'Employee ID Cards',
                'Work Badges',
                'Security Clearances' ],
            details: [
                {
                    content:"I want to ISSUE work credentials",
                    link: "https://issuer.mynext.id/",
                },
                {
                    content:"I want to VERIFY work credentials",
                    link: "https://verifier.mynext.id/",
                },
                {
                    content:"I want to PROVE work credentials",
                    link: "",
                },
            ]
            
        },{
            title: "GOVERNMENT",
            color: "#108EF5",
            desc:"Issue and verify standardized government documents conveniently managed in users' digital wallets.",
            examples:["Driver's Licence",
            "Social Security Card",
            "Green Card", 
            "Citizen Identitiy",
            "Business Licence",
            "Voter ID",
            "Authorized Verification",
            ],
            details: [
                {
                    content:"I want to ISSUE government credential",
                    link: "https://issuer.mynext.id/",
                },
                {
                    content:"I want to VERIFY government credentials",
                    link: "https://verifier.mynext.id/",
                },
                {
                    content:"I want to PROVE government credentials",
                    link: "",
                },
            ]
        },
        {
            title: "CONTENT",
            color: "#FF35A5",
            desc:"Establish authorship for digital works like books, music, and art, and protect creative content.",
            examples:['Proof of Authorship',
                'Verified Articles Badge', 'Book, Music or Art Rights'],
            details: [
                {
                    content:"I want to ISSUE content credential",
                    link: "https://issuer.mynext.id/",
                },
                {
                    content:"I want to VERIFY content credential",
                    link: "https://verifier.mynext.id/",
                },
                {
                    content:"I want to PROVE content credential",
                    link: "",
                },
            ]
        },
        {
            title: "PRODUCT & RETAIL",
            color: "#03F0AA",
            desc:"Simplify retail credentialing, offering QR code access to verified data for transparency and compliance.",
            examples:['Digital Product Passport',
                'Retailer Trust Badge',
                'Verifiable Tickets',
                'Supply Chain Traceability',
                'Age Verification Certificate'],
            details: [
                {
                    content:"I want to ISSUE product & retail credentials",
                    link: "https://issuer.mynext.id/",
                },
                {
                    content:"I want to VERIFY product & retail credentials",
                    link: "https://verifier.mynext.id/",
                },
                {
                    content:"I want to PROVE product & retail credentials",
                    link: "",
                },
            ]
        },
        {
            title: "EDUCATION",
            color: "#FFBA34",
            desc:"Empower students to share achievements, modernize learning, and showcase your institution's contemporary approach",
            examples:['Student Cards',
                'Micro Credentials',
                'Academic Certificates',
                'Professional Credentials',
                ],
            details: [
                {
                    content:"I want to ISSUE education credentials",
                    link: "https://issuer.mynext.id/",
                },
                {
                    content:"I want to VERIFY education credentials",
                    link: "https://verifier.mynext.id/",
                },
                {
                    content:"I want to PROVE education credentials",
                    link: "",
                },
            ]
        }

    ])
}
