import React, { useState, useEffect } from "react";
import Footer from "../MynextId/Footer/Footer";

import Navigation from "../MynextId/Navigation/Navigation";
import TrustBuildingSolution from "./TrustBuildingSolution";
import AboutHome from "./AboutHome";

const AboutUs = () => {
    useEffect(() => {
        require("../../../scss/myNextId.scss");
    }, []);

    const [sidebarOpened, setSidebarOpened] = useState(false);

    return (
        <div className={sidebarOpened ? "main-theme overlay" : "main-theme"}>
            <Navigation
                sidebarOpened={() => setSidebarOpened(!sidebarOpened)}
            />
            <AboutHome />
            <TrustBuildingSolution />
            <Footer />
        </div>
    );
};

export default AboutUs;
