import React, { useState, useEffect } from 'react'

import Navigation from "../MynextId/Navigation/Navigation";
import NewsArticles from './NewsArticles';
import NewsHome from './NewsHome';
import Footer from '../MynextId/Footer/Footer'

const News = () => {

    useEffect(() => {
        require("../../../scss/myNextId.scss");
    }, []);
    const [sidebarOpened, setSidebarOpened] = useState(false);

    return (
        <div className={sidebarOpened ? "main-theme overlay" : "main-theme"}>
            <Navigation sidebarOpened={() => setSidebarOpened(!sidebarOpened)}/>
            <NewsHome/>
            <NewsArticles/>
            <Footer />
        </div>
    )
}

export default News
