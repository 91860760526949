import React from "react";

import buildingImg from "../../../img/Dpp/building-el.png";

const HowDPPWorks = () => {
    return (
        <div className="how-dpp-works-section">
            <div className="container-image">
                <div className="shadow-container"></div>
                <img src={buildingImg} className="shadow-img" alt="" />
            </div>

            <div className="container">
                <p className="info-text">HOW DIGITAL PRODUCT PASSPORT WORKS?</p>
                <h2 className="sub-header">
                    Your Product’s Tale, One QR Scan Away
                </h2>
                <p className="desc">
                    Digital Product Passport combines simplicity with advanced
                    technology. We enable you to market each product
                    individually, providing your customers with specific
                    information in a tamper-proof way. A single QR scan on a
                    consumer smartphone unveils your product’s entire journey -
                    from raw materials to the final item. It’s all about
                    clarity, transparency, and technology-driven assurance.
                </p>
            </div>
        </div>
    );
};

export default HowDPPWorks;
