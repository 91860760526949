import React from "react";
import homeElement from "../../../img/News/home-element-id.png";

const NewsHome = () => {
    return (
        <div className="news-home-section">
            <div className="home-text">
                <h1 className="header">
                    <span className="home-text-el">
                        <span className="home-text-underline">&nbsp;</span>
                        <span className="home-text-underline">News</span>
                        <span className="home-text-underline">&nbsp;</span>
                    </span>
                </h1>

                <p className="home-text-desc desc">
                    Get the latest updates on digital ID and trust ecosystems,
                    EU regulations, and everything in between, along with
                    company news and results, all in one place.
                </p>
            </div>

            <img className="home-element-image" src={homeElement} alt="home-element-image"/>
        </div>
    );
};

export default NewsHome;
