import React, { useState } from "react";

function ShowcaseBoxLink(props: any) {
    const [hovered, setHovered] = useState(false);
    return (
        <div
            className="bottom"
            style={{ color: hovered ? props.color : "white" }}
            onClick={() => {
                if (props.link !== "") {
                    window.open(props.link, "_blank");
                }
            }}
        >
            <p
                style={{ width: "fit-content" }}
                onMouseOver={() => {
                    setHovered(true);
                }}
                onMouseLeave={() => {
                    setHovered(false);
                }}
            >
                {props.content} <br></br>
                {props.index === 2 && (
                    <span style={{ fontSize: "12px", display: "block" }}>
                        (coming soon)
                    </span>
                )}
            </p>

            <p
                onMouseOver={() => {
                    setHovered(true);
                }}
                onMouseLeave={() => {
                    setHovered(false);
                }}
            >
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2 16H28"
                        stroke={hovered ? props.color : "white"}
                        strokeWidth="4"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M19 25L28 16L19 7"
                        stroke={hovered ? props.color : "white"}
                        strokeWidth="3.89222"
                        strokeMiterlimit="10"
                    />
                </svg>
            </p>
        </div>
    );
}

export default ShowcaseBoxLink;
