import React, {useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./scss/index.css";

import AboutUs from "./Components/Pages/AboutUs/AboutUs";
import News from "./Components/Pages/News/News";
import MyNextIdApp from "./Components/Pages/MynextId/MyNextIdApp";
import NewsArticle from "./Components/Pages/News/NewsArticle";
import VerifierApp from "./Components/Pages/Verifier/VerifierApp";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy/PrivacyPolicy";
import DppApp from "./Components/Pages/Dpp/DppApp";

function App() {
  
  // const VerifierApp = React.lazy(() => import("./Components/Pages/Verifier/VerifierApp"))

  return (
    <BrowserRouter>
        <Routes>
            <Route path="*" element={<MyNextIdApp/>} />
            <Route path="/verifier" element={<VerifierApp/>} />
            <Route path="/dpp" element={<DppApp/>} />
            <Route path="/about-us" element={<AboutUs/>} />
            <Route path="/news" element={<News/>} />
            <Route path="/news/:id" element={<NewsArticle/>} />
            
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            
        </Routes>
    </BrowserRouter>
  );
}

export default App;
