import React, { useEffect, useState } from "react";

import "./scss/dpp.scss";
import Navigation from "./Navigation/Navigation";
import HomeSection from "./HomeSection";
import Footer from "./Footer/Footer";
import WhatIsDPP from "./WhatIsDPP";
import WhyDPPMatters from "./WhyDPPMatters";
import HowDPPWorks from "./HowDPPWorks";
import CoreFeatures from "./CoreFeatures";
import DigitalDocSection from "./DigitalDocSection";
import HowToLeadFuture from "./HowToLeadFuture";
import Pricing from "./Pricing";

const VerifierApp = () => {
    useEffect(() => {
        require("./scss/dpp.scss");
    }, []);
    const [sidebarOpened, setSidebarOpened] = useState(false);
    return (
        <div className={ sidebarOpened ? "main-theme overlay dpp-theme" : "main-theme dpp-theme"} >
            <div className={sidebarOpened ? "overlay" : ""}>
                <Navigation sidebarOpened={() => setSidebarOpened(!sidebarOpened)} />
                <HomeSection />
                <WhatIsDPP />
                <WhyDPPMatters />
                <HowDPPWorks />
                <CoreFeatures />
                <DigitalDocSection />
                <HowToLeadFuture />
                {/*<Pricing />
                <ContactForm />*/}
                <Footer />
            </div>
        </div>
    );
};

export default VerifierApp;
