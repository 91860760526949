import React from 'react'
import NewsData from "./NewsData";
import { useNavigate } from 'react-router-dom';

const NewsArticles = () => {
    const data = NewsData();
    const navigate = useNavigate();

    return (
        <div className="news-section">

            {data.map((item, index) => {
                return (
                    <div key={index+"_article"} className='news-section'>
                        <div className="container">
                            <img src={item.image} alt={`image-${index}`} />
                        </div>

                        <div className="content-news">
                            <p className="info-text">{item.date}</p>
                            <h2 className="sub-header truncate">{item.title}</h2>
                            <p className="desc truncate">{item.desc}</p>

                            <button className="btn-mynextid" onClick={() => { navigate('/news/'+item.id)/*window.open(item.link, "_blank");*/}}>
                            MORE
                            </button>
                        </div>
                    </div>
                );
            })}

           
        </div>
    )
}

export default NewsArticles
