import React from "react";
import trustImage from "../../../img/MyNextId/tech-img.jpg";

const TrustBuildingSolutions = () => {
    return (<>
        <div className="trust-building-solution-section">

            <div className="container" >
                    <div className="shadow-container"></div>
                    <img src={trustImage} alt="trust-building-image" />
            </div>
           

            <div className="content-digital">
                <p className="info-text">TRUST-BUILDING SOLUTIONS</p>
                <h2 className="sub-header">Digital World, Real Trust</h2>
                <p className="desc">
                    Our main goal is to build trust online. By connecting Web2 and Web3,
                    we ensure the safe sharing and verification of information while
                    keeping privacy intact, leading to fully digital processes for users,
                    businesses, and organizations.
                </p>
            </div>
        </div>
    </>);
};

export default TrustBuildingSolutions;
