import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../../img/Dpp/nav-logo.png";

function Footer() {
    const navigate = useNavigate();
    return (
      <div className="footer-section">
        <img src={logo} width="50px" height="50px" alt="logo" />
        <div className="footer-links">
          <a href="/">Home</a>
          <a href="/news">News</a>
          <a href="/about-us">About</a>
          {/*<a href="https://test-docs.mynext.id/api/v2/dpp" target="_blank">Documentation</a>*/}
        </div>
        <p>&copy; {new Date().getFullYear()} Netis d.o.o.</p>
        <p className="clickable" onClick={()=> navigate("/privacy-policy")}>Privacy policy</p>
      </div>
    );
  }

export default Footer;
