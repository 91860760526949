import React from "react";

import image1 from "../../../img/MyNextId/tech-logo-1.png";
import image2 from "../../../img/MyNextId/tech-logo-2.png";
import image3 from "../../../img/MyNextId/tech-logo-3.png";
import image4 from "../../../img/MyNextId/tech-logo-4.png";
import image5 from "../../../img/MyNextId/tech-logo-5.png";

const TechSection = () => {
    return (
        <div className="tech-section">
            <div className="tech-section-text">
                <p className="info-text">MEETING REGULATORY DEMANDS</p>
                <h2 className="sub-header">Technology In Sync With Compliance </h2>
                <p className="desc">
                    We work with EU bodies like EBSI, follow global standards, and
                    collaborate with experts and communities to ensure compliance. Our
                    commitment to open standards means we integrate credentials smoothly
                    to meet real needs.
                </p>
            </div>

           {/*  <div className="tech-section-logos">
                <img src={image1} alt="" />
                <img src={image2} alt="" />
                <img src={image3} alt="" />
                <img src={image4} alt="" />
                <img src={image5} alt="" />
            </div> */}
        </div>
    );
};

export default TechSection;
