import React from "react";
import digitalDocImg from "../../../img/Verifier/home-verifier-image-small.jpg";

const WhatIsDPP = () => {
    return (
        <div className="whatIsVerifier-section">
            <div className="container">
                <div className="shadow-container"></div>
                <img src={digitalDocImg} alt="whatIsVerifier-image" />
            </div>

            <div className="content-digital">
                <p className="info-text">
                    {"What is MyNextID_Verifier?".toUpperCase()}
                </p>
                <h2 className="sub-header">
                    Authenticate and Verify In One Easy Step
                </h2>
                <p className="desc">
                    MyNextID_Verifier simplifies verification by automating
                    manual checks and eliminating the need to contact issuers.
                    Your organization gains trusted status for managing verifier
                    apps across different settings with easy onboarding. Using
                    our app, your team can verify credentials quickly with a QR
                    code scan, ensuring simplicity, security, and privacy for
                    everyone involved.
                </p>
                {/* <button className="btn-mynextid" onClick={() => {}}>
          MORE
        </button> */}
            </div>
        </div>
    );
};

export default WhatIsDPP;