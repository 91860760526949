import React, { useEffect, useState } from "react";
import HomeData from "./HomeData";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import homeElement from "../../../img/MyNextId/home-element-id.png";

interface HomeSectionProps {}

export default function HomeSection(props: HomeSectionProps) {
    const data = HomeData();
    const [selectedElStart, setSelectedElStart] = useState(0);
    const [selectedElMain, setSelectedElMain] = useState(0);
    const [selectedElEnd, setSelectedElEnd] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedElStart((previous) => {
                if (previous === data.startText.length - 1) {
                    return 0;
                } else {
                    return previous + 1;
                }
            });
            setSelectedElMain((previous) => {
                if (previous === data.mainText.length - 1) {
                    return 0;
                } else {
                    return previous + 1;
                }
            });
            setSelectedElEnd((previous) => {
                if (previous === data.endText.length - 1) {
                    return 0;
                } else {
                    return previous + 1;
                }
            });
        }, 6000); //Change every 6 seconds

        return () => clearInterval(interval);
    }, [selectedElMain]);

    useEffect(() => {
        setTimeout(() => {
            document.body.style.setProperty(
                "--theme-color",
                data.mainText[selectedElMain].color
            );
        }, 200);
    }, [selectedElMain]);

    function visitLink() {
        let link = data.link;
        window.open(link, "_blank");
    }

    function existsInArray(arr: number[], val: number) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === val) {
                return true;
            }
        }
        return false;
    }

    return (
        <div className="home-section">
            <div className="home-text">
                <h1 className="text">
                    <SwitchTransition mode="out-in">
                        <CSSTransition
                            classNames="fade"
                            addEndListener={(
                                node: HTMLElement,
                                done: () => void
                            ) => {
                                node.addEventListener(
                                    "transitionend",
                                    done,
                                    false
                                );
                            }}
                            key={selectedElStart}
                        >
                            <span className="home-text-el">
                                <span className="home-text-underline">
                                    &nbsp;
                                </span>
                                {data.startText[selectedElStart]
                                    .split("")
                                    .map((char, key) => {
                                        return (
                                            <span
                                                key={key}
                                                className="home-text-underline"
                                            >
                                                {char}
                                            </span>
                                        );
                                    })}
                                <span className="home-text-underline">
                                    &nbsp;
                                </span>
                            </span>
                        </CSSTransition>
                    </SwitchTransition>
                    <SwitchTransition mode="out-in">
                        <CSSTransition
                            classNames="fade"
                            addEndListener={(
                                node: HTMLElement,
                                done: () => void
                            ) => {
                                node.addEventListener(
                                    "transitionend",
                                    done,
                                    false
                                );
                            }}
                            key={selectedElMain}
                        >
                            <span className=" home-text-el">
                                <span className="home-text-underline-main">
                                    &nbsp;
                                </span>
                                {data.mainText[selectedElMain].title
                                    .split("")
                                    .map((char, key) => {
                                        return (
                                            <span
                                                key={key}
                                                className="home-text-underline-main"
                                            >
                                                {char}
                                            </span>
                                        );
                                    })}
                                <span className="home-text-underline-main">
                                    &nbsp;
                                </span>
                            </span>
                        </CSSTransition>
                    </SwitchTransition>
                    <SwitchTransition mode="out-in">
                        <CSSTransition
                            classNames="fade"
                            addEndListener={(
                                node: HTMLElement,
                                done: () => void
                            ) => {
                                node.addEventListener(
                                    "transitionend",
                                    done,
                                    false
                                );
                            }}
                            key={selectedElEnd}
                        >
                            <span className=" home-text-el">
                                <span className="home-text-underline">
                                    &nbsp;
                                </span>
                                {data.endText[selectedElEnd]
                                    .split("")
                                    .map((char, key) => {
                                        return (
                                            <span
                                                key={key}
                                                className="home-text-underline"
                                            >
                                                {char}
                                            </span>
                                        );
                                    })}
                                <span className="home-text-underline">
                                    &nbsp;
                                </span>
                            </span>
                        </CSSTransition>
                    </SwitchTransition>
                </h1>

                <div className="home-link m-hide">
                    <button
                        className="btn-mynextid"
                        /*onClick={visitLink}*/ onClick={() => {
                            const section = document.querySelector(
                                "#getStartedCredentials"
                            );
                            section?.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                            });
                        }}
                    >
                        SEE IT IN ACTION
                    </button>
                </div>
            </div>

            {/*<img className="home-bg" src={data.mainText[selectedElMain].image} />*/}
            {data.mainText.map((val: any, index: number) => {
                return (
                    <img
                        key={"image_" + index}
                        className="home-bg"
                        src={val.image}
                        style={{
                            display:
                                index === selectedElMain ? "block" : "none",
                        }}
                        alt="Home-image"
                    />
                );
            })}
            <div className="home-link m-show">
                <button className="btn-mynextid" /*onClick={visitLink}*/ onClick={() => {
                    const section = document.querySelector('#getStartedCredentials');
                    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }}>
                    SEE IT IN ACTION
                </button>
            </div>
            <img className="home-element-image m-hide" src={homeElement} alt="ID-image"/>
        </div>
    );
}
