import React, { useEffect, useState } from 'react'
import NewsData from "./NewsData";
import { useNavigate, useParams, } from 'react-router-dom';
import Navigation from '../MynextId/Navigation/Navigation';
import Footer from '../MynextId/Footer/Footer';
import OtherNews from './OtherNews';
import Separator from './Separator';

const NewsArticle = () => {
    const navigate = useNavigate();
    const [sidebarOpened, setSidebarOpened] = useState(false);
    const {id} =  useParams();
    const [data, setData] = useState<any>({})

    useEffect(() => {
        const tempdata = NewsData().find((element) => element.id === id);
        if(tempdata !== undefined){
            setData(tempdata)
            //console.log(tempdata);
        }else{
            navigate('/news/');
        }
    },[id]);
    
    

    return (
        <div className={sidebarOpened ? "main-theme overlay" : "main-theme"}>
            <Navigation sidebarOpened={() => setSidebarOpened(!sidebarOpened)}/>

            <div className="news-article-section">
                {Object.keys(data).length !== 0 ? <>      
                    <div className="content-news">
                        <p className="info-text">{data.date}</p>
                        <h2 className="sub-header">{data.title}</h2>
                        <p className="desc">{data.desc}</p>

                    </div>

                    <div className="container">
                        <img src={data.image} alt="article-image" />
                    </div>

                    {data.details.map((item:any, index:any) => {
                        return (
                            <div key={index+"_article_detail"} >
                                <div >
                                    <h2 className="small-sub-header">{item.title}</h2>
                                    <p className="desc ">{item.desc}</p>
                                </div>
                            </div>
                        );
                    })}
                </>: <></>} 
            
            </div>

            <Separator/>

            <OtherNews/>
            
            <Footer />
        </div>
    )
}

export default NewsArticle
