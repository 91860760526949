import React from "react";
import homeImage from "../../../img/Verifier/mynextid-verifier-key-visual-small.jpg";

export default function HomeData() {
  return {
    startText: ["Verify", "Inspect", "Scan"],
    mainText: [
      {
        title: "Diploma",
        color: "#FFBA34",
      },
      {
        title: "Driver’s Licence",
        color: "#108EF5",
      },
      {
        title: "Employee Credential",
        color: "#8C72F7",
      },
      {
        title: "Book Rights Credential",
        color: "#03F0AA",
      },
      {
        title: "Digital Product Passport",
        color: "#03F0AA",
      },
    ],
    endText: ["In One Step", "Instantly", "With a QR Code"],
    link: "https://verifier.mynext.id",
    image: homeImage,
  };
}
