import React from "react";
import image0 from "../../../img/MyNextId/home-img-0.jpg";
import image1 from "../../../img/MyNextId/home-img-1.jpg";
import image2 from "../../../img/MyNextId/home-img-2.jpg";
import image3 from "../../../img/MyNextId/home-img-3.jpg";
import image4 from "../../../img/MyNextId/home-img-4.jpg";


export default function HomeData() {
  return {
    startText: ["Issue", "Verify", "Manage"],
    mainText: [
      {
        title: "Diploma",
        color: "#FFBA34",
        image: image4,
      },
      {
        title: "Driver’s Licence",
        color: "#108EF5",
        image: image1,
      },
      {
        title: "Employee Credential",
        color: "#8C72F7",
        image: image0,
      },
      {
        title: "Book Rights Credential",
        color: "#FF35A5",
        image: image2,
      },
      {
        title: "Digital Product Passport",        
        color: "#03F0AA",
        image: image3,
      },
    ],
    endText: ["Digitally", "Instantly", "In One Place"],
    link: "https://verifier.mynext.id",
    
  };
}
