import React from "react";

function Credentialing4USection() {
  return (
    <div className="cred-4-u-section">
      <div className="cred-text">
        <h2 className="small-sub-header">
          Your company gets all it needs for instant issuance, management, and
          verification of credentials, conveniently stored in users' digital
          wallets.
        </h2>
        <h3 className="smaller-sub-header">In short, we simplify credentialing for you!</h3>
      </div>
    </div>
  );
}

export default Credentialing4USection;