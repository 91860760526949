import React from "react";
import homeImg3 from "../../../img/Dpp/home-img-3.jpg";


export default function HomeData (){
    return ([
        {
            title: ["From Source to Shelf:","Every Product","Tells a Tale"],
            highLightEl:[0, 1, 2],
            image: homeImg3,
            color: "#03F0AA",
            link: "https://dpp.mynext.id/",
        },

    ])
}
