import React, {useState, useEffect} from "react";
import Navigation from "../MynextId/Navigation/Navigation";
import Footer from "../MynextId/Footer/Footer";
import PrivacyPolicyText from "./PrivacyPolicyText";


function PrivacyPolicy() {
    useEffect(() => {
        require("../../../scss/myNextId.scss");
    }, []);

    const [sidebarOpened, setSidebarOpened] = useState(false);

    return (
        <div className={sidebarOpened ? "main-theme overlay" : "main-theme"}>
            <Navigation
                sidebarOpened={() => setSidebarOpened(!sidebarOpened)}
            />
            <PrivacyPolicyText />
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;